import React from "react";
import './footer.css'; 

const Footer = () => {

          return(
              <div className='footer'> 
<p>Charles Davis - Web Developer - 2022</p>
              </div>

        )
    }

export default Footer ;