import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import './contact.css'

const Contact = (props) => {                  

          return(
            <div className='contact'>
         <h2>Contact Details:</h2>
          <p>Email: north.witham@gmail.com</p>
          <p>Address: Post Lane, North Witham, NG33 5JZ</p>
          <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9651.941818793208!2d-0.6292307!3d52.7865704!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdde8fae4309fac25!2sNorth%20Witham%20Village%20Hall!5e0!3m2!1sen!2suk!4v1671018595972!5m2!1sen!2suk" width="600" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>

        )
    }

export default Contact ;