import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import Header from './components/header/header'; 
import Home from './components/home/home';
import Calendar from './components/calendar/calendar';
import Facebook from './components/facebook/facebook';
import Instagram from './components/instagram/instagram';
import Contact from './components/contact/contact';
import Footer from './components/footer/footer'

function App() {
  return (
    <div className="App">
    <Router>
    <Routes>
      <Route exact path = '/' element={<div > <Header/> <Home /> <Footer /></div>}/>  
      <Route exact path = '/calendar' element={<div > <Header/> <Calendar /> <Footer /></div>}/> 
      <Route exact path = '/facebook' element={<div > <Header/> <Facebook/> <Footer /></div>}/>     
      <Route exact path = '/instagram' element={<div > <Header/> <Instagram/> <Footer /></div>}/>           
      <Route exact path = '/contact-map' element={<div > <Header/> <Contact /> <Footer /></div>}/> 
    </Routes>
  </Router>
      
    </div>
  );
}

export default App;
