import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import './instagram.css'; 

const Instagram = (props) => {                  

          return(
            <div className='instagram'>
           <iframe title='instagram' src="https://widget.taggbox.com/116925" style={{width:'100%', height:'600px', border:'none'}}></iframe>
              </div>

        )
    }

export default Instagram ;