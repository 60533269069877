import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import './calendar.css'

const Calendar = (props) => {                  

          return(
            <div className='calendar'>
                <h2>Calendar of events:</h2>
                <iframe title='calendar' src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Europe%2FLondon&src=bm9ydGh3aXRoYW12aEBnbWFpbC5jb20&color=%23039BE5" style={{border:'solid 1px #777'}} width="800" height="600" frameBorder="0" scrolling="no"></iframe>
                 </div>

        )
    }

export default Calendar ;