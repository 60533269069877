import React, { useState, useEffect } from "react";
import {DiscussionEmbed} from "disqus-react"; 
import { NavLink } from 'react-router-dom';
import './home.css'

const Home = (props) => {  
  const disqusShortname = "villagehall-com"
  const disqusConfig = {
    url: "https://villagehall.com",
    identifier: 'article-id', // Single post id
    title: 'Title' // Single post title
  }                  

          return(
            <div className='home'>
              <p className='intro'>
              North Witham Village Hall CS, is run by volunteer committee members and is wholly to raise funds to enable us to provide a community hub, The field is a multi-use area, so you are likely to see people and their dog crossing on the public footpath, families and children making use of the open space and the play equipment, and on occasions events in the village hall, we believe this adds to the appeal of our rural site. We are situated in a small rural village with countryside views from site yet have great road access from the A1 and are ideal for transit stop offs. We also have a wealth of countryside to explore with great cycle routes and well maintained footpaths around the immediate vicinity and an array of interesting places if your able to stay a little longer.
              </p>
              <p className='intro'>
                On the site you can view our events calendar, Facebook feed and Instagram gallery. 
              </p>
      
          </div>

        )
    }

export default Home ;